import * as React from "react";

import { cn } from "@/lib/utils";

import { ButtonIconProps } from "@/components/shared/types";

const ButtonIcon: React.FC<ButtonIconProps> = ({ variant = "primary", className, children, ...rest }) => {
    return (
        <button
            type="button"
            className={cn(
                " flex items-center justify-center",
                [
                    variant === "primary" && "bg-primary-500 min-h[38px] h-[38px] min-w-[42px] w-[42px]",
                    variant === "secondary" && "border-blackShade-15 h-6 w-6 rounded-full border bg-white",
                ],
                className,
            )}
            {...rest}
        >
            {children}
        </button>
    );
};

export default ButtonIcon;
