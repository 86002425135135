export const NAV_LINKS = [
    {
        title: "Service",
        links: [
            {
                label: "Serviceportal",
                sublinks: [
                    { label: "Zur Übersicht", href: "https://www.neueleben.de/serviceportal.html" },
                    {
                        label: "Betriebliche Altersvorsorge",
                        sublinks: [
                            {
                                label: "Arbeitgeber:in",
                                href: "https://www.neueleben.de/serviceportal/bav/arbeitgeber.html",
                            },
                            {
                                label: "Arbeitnehmer:in",
                                href: "https://www.neueleben.de/serviceportal/bav/arbeitnehmer.html",
                            },
                        ],
                    },
                    { label: "Private Altersvorsorge", href: "https://www.neueleben.de/serviceportal/pav.html" },
                    { label: "Riester-Verträge", href: "https://www.neueleben.de/serviceportal/riester.html" },
                ],
            },
            { label: "Feedback", href: "https://www.neueleben.de/feedback.html" },
            { label: "Services für Vertriebspartner", href: "https://www.neueleben.de/service/vertriebspartner.html" },
            { label: "Basisinformationsblatt", href: "https://www.neueleben.de/basisinformationsblatt.html" },
        ],
    },
    {
        title: "Produkte",
        links: [
            { label: "Zur Übersicht", href: "https://www.neueleben.de/produkte.html" },
            {
                label: "Altersvorsorge",
                sublinks: [
                    {
                        label: "Female Finance",
                        href: "https://www.neueleben.de/Female-Finance.html",
                    },
                    {
                        label: "Private Altersvorsorge",
                        sublinks: [
                            {
                                label: "Zur Übersicht",
                                href: "https://www.neueleben.de/Private-Rente.html",
                            },
                            {
                                label: "Future now Kids",
                                href: "https://www.neueleben.de/futurenow-kids.html",
                            },
                            {
                                label: "Future now",
                                href: "https://www.neueleben.de/futurenow.html",
                            },
                            {
                                label: "Garantiepolice",
                                href: "https://www.neueleben.de/garantiepolice.html",
                            },
                            {
                                label: "Aktivplan",
                                href: "https://www.neueleben.de/Aktivplan.html",
                            },
                            {
                                label: "Aktivplan Kids",
                                href: "https://www.neueleben.de/Aktivplan-Kids.html",
                            },
                            {
                                label: "Strategieportfolio",
                                href: "https://www.neueleben.de/Strategieportfolio.html",
                            },
                            {
                                label: "Sofortrente",
                                href: "https://www.neueleben.de/Sofortrente.html",
                            },
                            {
                                label: "PlanX",
                                href: "https://www.neueleben.de/PlanX.html",
                            },
                        ],
                    },
                    {
                        label: "Geförderte Altersvorsorge",
                        sublinks: [
                            {
                                label: "Aktivplan Basis",
                                href: "https://www.neueleben.de/Aktivplan-Basis.html",
                            },
                        ],
                    },
                    {
                        label: "Betriebliche Altersvorsorge",
                        sublinks: [
                            {
                                label: "Direktversicherung",
                                href: "https://www.neueleben.de/Direktversicherung.html",
                            },
                            {
                                label: "Unterstützungskasse",
                                href: "https://www.neueleben.de/Unterstuetzungskasse.html",
                            },
                        ],
                    },
                ],
            },
            {
                label: "Risiken absichern",
                sublinks: [
                    {
                        label: "Zur Übersicht",
                        href: "https://www.neueleben.de/versicherungen.html",
                    },
                    {
                        label: "Grundfähigkeitsversicherung",
                        href: "https://www.neueleben.de/grundfaehigkeitsschutz.html",
                    },
                    {
                        label: "Berufsunfähigkeitsversicherung",
                        href: "https://www.neueleben.de/Einkommensschutz.html",
                    },
                    {
                        label: "Risikoversicherung",
                        href: "https://www.neueleben.de/Risikoversicherung.html",
                    },
                    {
                        label: "Unfallplus",
                        href: "https://www.neueleben.de/Unfallplus.html",
                    },
                    {
                        label: "Unfallassist",
                        href: "https://www.neueleben.de/Unfallassist.html",
                    },
                    {
                        label: "Nachlassschutz",
                        href: "https://www.neueleben.de/Nachlassschutz.html",
                    },
                    {
                        label: "Pflegerentenversicherung",
                        href: "https://www.neueleben.de/Pflegerentenversicherung.html",
                    },
                    {
                        label: "Kreditschutz-Privat",
                        href: "https://www.neueleben.de/Kreditschutz-Privat.html",
                    },
                    {
                        label: "Gewerbe-Kreditversicherung",
                        href: "https://www.neueleben.de/Gewerbe-Kreditversicherung.html",
                    },
                    {
                        label: "Baufinanzierung",
                        href: "https://www.neueleben.de/Baufinanzierung.html",
                    },
                ],
            },
        ],
    },
    {
        title: "Ratgeber",
        links: [
            { label: "Ratgeber", href: "https://www.neueleben.de/ratgeber.html" },
            { label: "Glossar", href: "https://www.neueleben.de/Glossar.html" },
        ],
    },
    {
        title: "Unternehmen",
        links: [
            { label: "Zur Übersicht", href: "https://www.neueleben.de/unternehmen.html" },
            {
                label: "neue leben",
                sublinks: [
                    {
                        label: "Zur Übersicht",
                        href: "https://www.neueleben.de/unternehmen.html",
                    },
                    {
                        label: "Wir über uns",
                        href: "https://www.neueleben.de/wir-ueber-uns.html",
                    },
                    {
                        label: "Management",
                        sublinks: [
                            {
                                label: "Zur Übersicht",
                                href: "https://www.neueleben.de/management.html",
                            },
                            {
                                label: "Vorstand",
                                href: "https://www.neueleben.de/vorstand.html",
                            },
                            {
                                label: "Aufsichtsrat",
                                href: "https://www.neueleben.de/aufsichtsrat.html",
                            },
                        ],
                    },
                    {
                        label: "Gesellschaften und Aktionäre",
                        href: "https://www.neueleben.de/gesellschaften-aktionaere.html",
                    },
                    {
                        label: "Zahlen, Daten, Fakten",
                        sublinks: [
                            {
                                label: "Zur Übersicht",
                                href: "https://www.neueleben.de/zahlen-daten-fakten.html",
                            },
                            {
                                label: "Finanzberichte",
                                href: "https://www.neueleben.de/finanzberichte.html",
                            },
                            {
                                label: "Ertragsquellen",
                                href: "https://www.neueleben.de/ertragsquellen.html",
                            },
                        ],
                    },
                ],
            },
            { label: "Ratings und Auszeichnungen", href: "https://www.neueleben.de/ratings.html" },
            { label: "Nachhaltigkeit", href: "https://www.neueleben.de/nachhaltigkeit.html" },
            { label: "Karriere", href: "https://www.neueleben.de/Karriere.html" },
            { label: "Presse", href: "https://www.neueleben.de/presse.html" },
            { label: "Compliance", href: "https://www.neueleben.de/compliance.html" },
        ],
    },
];
