import { ENV_PAGE_BASE_URL, ENV_STRAPI_URL } from "utils/env.dts";

export const prependStrapiUrl = (path: string) => {
  return ENV_STRAPI_URL + path;
};

export const prependPageUrl = (path: string) => {
  return ENV_PAGE_BASE_URL + path;

};



