import * as React from "react";

import { cn } from "@/lib/utils";

type LinkNavbarProps = React.ComponentProps<"button"> & {
    children?: React.ReactNode;
    className?: string;
};

const LinkNavbar: React.FC<LinkNavbarProps> = ({ children, className, ...rest }) => {
    return (
        <button
            className={cn(
                "flex h-[40px] items-center px-6 py-[10px]",
                "font-frutigerRoman text-center text-[16px] leading-[135%] text-neutral-600 font-normal",
                "hover:bg-primary-500 hover:text-white",
                className,
            )}
            {...rest}
        >
            {children}
        </button>
    );
};

export default LinkNavbar;
